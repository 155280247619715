import Constants from "../Constants";
import { fetchGet } from "./CommonServiceUtils";

class AdminAuthService {
  static async impersonate(organizationId: string) {
    return fetchGet(
      `${Constants.REACT_APP_API_BASE_URL}admin/auth/impersonate/${organizationId}`
    );
  }
}

export default AdminAuthService;
