import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { UilColumns } from "@iconscout/react-unicons";

interface ColumnSelectorProps {
  visibleColumns: { [key: string]: boolean };
  toggleColumnVisibility: (column: string) => void;
}

const ColumnSelectorDropdown: React.FC<ColumnSelectorProps> = ({
  visibleColumns,
  toggleColumnVisibility
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedCount = Object.values(visibleColumns).filter(Boolean).length;

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggleDropdown}
      className="column-selector-dropdown"
    >
      <DropdownToggle tag="button" type="button" className="btn">
        <UilColumns size={20} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Select Columns</DropdownItem>
        {Object.keys(visibleColumns).map((column) => (
          <DropdownItem
            key={column}
            toggle={false}
            className="d-flex align-items-center"
          >
            <input
              type="checkbox"
              checked={visibleColumns[column]}
              onChange={() => {
                if (selectedCount < 5 || visibleColumns[column]) {
                  toggleColumnVisibility(column);
                }
              }}
              disabled={!visibleColumns[column] && selectedCount >= 6}
              className="me-2"
            />
            <label
              className="mb-0"
              style={{
                color:
                  !visibleColumns[column] && selectedCount >= 5
                    ? "gray"
                    : "black"
              }}
            >
              {column}
            </label>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ColumnSelectorDropdown;
