import { useEffect, ReactElement, useCallback } from "react";
import { useAuthStore, useOrganizationStore } from "../store";
import { getOragnizationData } from "../../services";
import { PerCreditValue } from "@shoutout-labs/marketbuzz-constants";
import useWebSocketData from "../../services/WebSocketUtil";
import Constants from "../../Constants";
import GTMService from "../../services/GTMService";

const OrganizationStoreProvider = ({
  children
}: {
  children: ReactElement;
}) => {
  const [isLoggedIn, organizationId] = useAuthStore((state) => [
    state.isLoggedIn,
    state.organizationId
  ]);
  const [{ setCredits, setDollarValue, setOrganization, setSyncDataResponse }] =
    useOrganizationStore((state) => [state]);

  const getOrganizationCredits = useCallback(async () => {
    try {
      const response = await getOragnizationData();
      setCredits(response?.credits as number);
      if (response?.credits && response?.credits > 0) {
        const dollarValue = response?.credits * PerCreditValue;
        setDollarValue(dollarValue);
      }
      setOrganization(response);
      GTMService.userProfileUpdateEvent({
        organizationId: response.id,
        name: response.name,
        country: response.country,
        timeZone: response.timeZone,
        status: response.status,
        createdOn: response.createdOn,
        ...(response.features
          ? { featureList: response.features.toString() }
          : {}),
        ...(response.credits ? { credits: response.credits } : {})
      });
    } catch (e) {
      console.error(e);
    }
  }, [setCredits, setDollarValue, setOrganization]);

  const { dataResponse } = useWebSocketData(
    `${Constants.REACT_APP_WEBSOCKET_BASE_URL}?token=${useAuthStore.getState().token}`
    //Default request message (action) is WebsocketMessageAction.GET_SYNC_PROGRESS. No need to pass it therefore.
  );

  useEffect(() => {
    if (dataResponse) {
      if (dataResponse !== null || dataResponse !== "") {
        // TODO: better validations??
        setSyncDataResponse(dataResponse);
      }
    }
  }, [setSyncDataResponse, dataResponse]);

  useEffect(() => {
    if (isLoggedIn() && organizationId) {
      getOrganizationCredits();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isLoggedIn, organizationId]);

  return children;
};

export default OrganizationStoreProvider;
