import React from "react";
import ReactApexChart from "react-apexcharts";

const BarLineChart = ({ series, options }: any) => {
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        //type="line"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export { BarLineChart };
