import { useEffect } from "react";

const NonAuthLayout = ({ children }: any) => {
  useEffect(() => {
    document.body.setAttribute("data-bs-theme", "light");

    return () => {
      document.body.removeAttribute("data-bs-theme");
    };
  }, []);
  return <div>{children}</div>;
};

export default NonAuthLayout;
