import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";
import {
  TOrganizationGetResponse,
  TOrganizationListGetResponse,
  TOrganizationSearchResponse
} from "@shoutout-labs/market_buzz_crm_types";

const getOragnizationData = (): Promise<TOrganizationGetResponse> => {
  return fetchGet(`${Constants.REACT_APP_API_BASE_URL}organizations`);
};

const getOragnizations = (): Promise<TOrganizationListGetResponse> => {
  return fetchGet(`${Constants.REACT_APP_API_BASE_URL}organizations/list`);
};

const searchOragnizations = (
  queryObj: object
): Promise<TOrganizationSearchResponse> => {
  return fetchGet(
    `${Constants.REACT_APP_API_BASE_URL}organizations/searchOrganization?${jsonToQueryParam(queryObj)}`
  );
};
export { getOragnizationData, getOragnizations, searchOragnizations };
