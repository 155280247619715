import { useCallback, useState } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Col, Input, Row, Button } from "reactstrap";
import { Utility } from "../../utils";

interface DateRangeSelectorProps {
  setSelectionRange: any;
  selectionRange: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  setSelectionRange,
  selectionRange
}) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [dateRangeSelectionRange, setdateRangeSelectionRange] = useState(
    Utility.defaultDateRange()
  );

  const handleSelect = useCallback(
    (ranges: any) => {
      setdateRangeSelectionRange(ranges.selection);
    },
    [setdateRangeSelectionRange]
  );

  const toggleDateRangePicker = useCallback(() => {
    setShowDateRangePicker((prev) => !prev);
  }, []);

  const handleDoneClick = useCallback(() => {
    setSelectionRange(dateRangeSelectionRange);
    setShowDateRangePicker(false);
  }, [setSelectionRange, dateRangeSelectionRange]);

  const handleClearClick = useCallback(() => {
    setdateRangeSelectionRange(Utility.defaultDateRange());
  }, [setdateRangeSelectionRange]);

  return (
    <>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <Row className="g-3 mb-0 align-items-center">
              <div className="col-sm-auto">
                <div className="input-group">
                  <Input
                    className="form-control border dash-filter-picker"
                    type="text"
                    value={`${selectionRange.startDate.toDateString()} - ${selectionRange.endDate.toDateString()}`}
                    onClick={toggleDateRangePicker}
                    readOnly
                  />
                  <span className="input-group-text bg-primary border-primary text-white">
                    <i className="ri-calendar-2-line"></i>
                  </span>
                  <div className="m-3 fst-italic text-primary">
                    Processed Between{" "}
                    {moment(selectionRange.startDate).format("YYYY-MM-DD")} to{" "}
                    {moment(selectionRange.endDate).format("YYYY-MM-DD")}
                  </div>
                  {showDateRangePicker && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 1000,
                        top: "100%",
                        left: 0,
                        backgroundColor: "white",
                        padding: "10px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                      }}
                    >
                      <DateRangePicker
                        ranges={[dateRangeSelectionRange]}
                        onChange={handleSelect}
                      />
                      <div className="d-flex justify-content-end mt-2">
                        <Button
                          color="primary"
                          className="me-2"
                          onClick={handleDoneClick}
                        >
                          Done
                        </Button>
                        <Button color="secondary" onClick={handleClearClick}>
                          Clear
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
