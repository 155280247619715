import { Container } from "reactstrap";
//import BreadCrumb from "../../Common/BreadCrumb";
import BillingInfo from "./BillingInfo";
import BillingPackages from "./BillingPackages";
import InvoiceList from "./InvoiceList";

const BillingPage = () => {
  document.title = "Billing | MarketBuzz";
  return (
    <div className="page-content">
      <Container fluid>
        {/* <BreadCrumb title="Billing" pageTitle="Billing" /> */}
        <h5>Buzz Credits</h5>
        <BillingInfo />
        <BillingPackages />
        <InvoiceList />
      </Container>
    </div>
  );
};

export default BillingPage;
