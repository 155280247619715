import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import {
  UilUserCircle,
  UilShoppingCart,
  UilTransaction,
  UilArrowCircleLeft
} from "@iconscout/react-unicons";
import numeral from "numeral";
import { useConfigStore } from "../../../Store/store";

const iconMap = {
  new_customers: (color: string) => <UilUserCircle size={40} color={color} />,
  sales: (color: string) => <UilShoppingCart size={40} color={color} />,
  transactions: (color: string) => <UilTransaction size={40} color={color} />,
  return_customers: (color: string) => (
    <UilArrowCircleLeft size={40} color={color} />
  )
};

interface SummaryCardProps {
  title: string;
  count: number;
  percentageValue: number;
  dateRange: number;
  id: any;
  isAllowDecimal: boolean;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  count,
  percentageValue,
  dateRange,
  id,
  isAllowDecimal
}) => {
  const [config] = useConfigStore((state) => [state.config]);

  return (
    <>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
        </CardHeader>
        <CardBody className="p-0 pb-2">
          <Row>
            <Col xxl={10} xl={10} lg={10} md={10} sm={9}>
              <div className="p-3">
                <h2 className="mb-1">
                  {isAllowDecimal
                    ? numeral(count).format("0,0")
                    : numeral(count).format("0.00a")}
                </h2>
                {dateRange > -1 && (
                  <p className="text-muted mb-0 mt-2">
                    {percentageValue >= 0 ? (
                      <span className="text-primary ms-1 fs-12 mr-3">
                        {numeral(percentageValue).format("0.00")}%
                        <i className="ri-arrow-up-fill ms-1 align-middle" />
                      </span>
                    ) : (
                      <span className="text-danger ms-1 fs-12 mr-3">
                        {numeral(percentageValue).format("0.00")}%
                        <i className="ri-arrow-down-fill ms-1 align-middle" />
                      </span>
                    )}
                    VS.{" "}
                    {dateRange === 0
                      ? "yesterday"
                      : `previous ${dateRange} days`}
                  </p>
                )}
              </div>
            </Col>
            <Col xxl={2} xl={2} lg={2} md={2} sm={3}>
              {iconMap[id as keyof typeof iconMap](config?.primaryColor)}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SummaryCard;
