import { fetchGet, jsonToQueryParam } from "./CommonServiceUtils";
import Constants from "../Constants";
import {
  TTemplateGetRequest,
  TTemplateGetResponse
} from "@shoutout-labs/market_buzz_crm_types";

const getTemplates = (
  queryObj: TTemplateGetRequest
): Promise<TTemplateGetResponse> => {
  return fetchGet(
    `${Constants.REACT_APP_API_BASE_URL}campaigns/templates?${jsonToQueryParam(queryObj)}`
  );
};

export { getTemplates };
