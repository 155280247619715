import { useState, useCallback, useEffect } from "react";
import { Table } from "reactstrap";
import Paginaion from "../../Common/Pagination";
import { getCampaigns, getCampaignsCount } from "../../../services";
import { TCampaignModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { useAuthStore } from "../../../Store/store";
import moment from "moment";
import Loader from "../../Common/Loader";
import "./CampaignHistoryTable.scss";

const CampaignHistoryTable: React.FC<{ activeTab: string }> = ({
  activeTab
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [campaigns, setCampaigns] = useState<TCampaignModelJSON[]>([]);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [loading, setLoading] = useState<boolean>(false);
  const [campaignsCount, setCampaignsCount] = useState(0);

  const loadCampaignData = useCallback(async () => {
    try {
      setLoading(true);
      const queryObj = {
        limit,
        skip
      };
      const [campaignData, campaignCountData] = await Promise.all([
        getCampaigns(queryObj),
        getCampaignsCount()
      ]);

      setCampaigns(campaignData.items);
      setCampaignsCount(campaignCountData.count);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [limit, skip, setCampaigns]);

  useEffect(() => {
    if (isLoggedIn() && activeTab === "2") {
      loadCampaignData();
    }
  }, [isLoggedIn, loadCampaignData, activeTab]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="table-responsive table-card">
            <Table className="align-middle table-nowrap mb-0 campaignTable">
              <thead className="table-light">
                <tr>
                  <th scope="col" className="wrapContent">
                    Campaign Name
                  </th>
                  <th scope="col">Message Text</th>
                  <th scope="col" className="wrapContent">
                    Sent Date & Time
                  </th>
                  <th scope="col">Status</th>
                  <th scope="col" className="wrapContent">
                    No. Customers
                  </th>
                  <th scope="col">Credits</th>
                  <th scope="col" className="wrapContent">
                    Processed Count
                  </th>
                  <th scope="col" className="wrapContent">
                    Sent Count
                  </th>
                  {/* <th scope="col">Success Count</th> */}
                </tr>
              </thead>
              <tbody>
                {campaigns.length > 0 &&
                  campaigns.map((campaign) => {
                    return (
                      <tr key={campaign.id}>
                        <td>{campaign.name}</td>
                        <td className="wrapContent">{campaign.content}</td>
                        <td className="countsCol">
                          {moment(campaign.updatedOn).format("LL LTS")}
                        </td>
                        <td>{campaign.status}</td>
                        <td className="countsCol">
                          {campaign.report?.customersCount || 0}
                        </td>
                        <td className="countsCol">
                          {campaign.estimatedCost || 0}
                        </td>
                        <td className="countsCol">
                          {campaign.report?.processedMessageCount || 0}
                        </td>
                        <td className="countsCol">
                          {campaign.report?.sentMessageCount || 0}
                        </td>
                        {/* <td>{campaign.report?.successMessageCount || 0}</td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                Showing{" "}
                <span className="fw-semibold"> {campaigns.length || 0}</span> of{" "}
                <span className="fw-semibold">{campaignsCount || 0}</span>{" "}
                Results
              </div>
            </div>
            <Paginaion
              dataCount={campaignsCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPageData={limit}
              setSkip={setSkip}
              loadData={loadCampaignData}
            />
            {campaigns.length === 0 && <p>No data found..</p>}
          </div>
        </>
      )}
    </>
  );
};

export default CampaignHistoryTable;
