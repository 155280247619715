import { useEffect, ReactElement } from "react";
import { useAuthStore, useConfigStore, useOrganizationStore } from "../store";
import { getConfigurationData } from "../../services";
import { useQuery } from "@tanstack/react-query";

const ConfigStoreProvider = ({ children }: { children: ReactElement }) => {
  const [isLoggedIn, initCompleted] = useAuthStore((state) => [
    state.isLoggedIn,
    state.initCompleted
  ]);
  const [setConfig, setIsLoadingConfigData] = useConfigStore((state) => [
    state.setConfig,
    state.setIsLoadingConfigData
  ]);
  const [organization] = useOrganizationStore((state) => [state.organization]);

  const { data, isLoading } = useQuery({
    queryKey: ["config"],
    queryFn: getConfigurationData,
    enabled: isLoggedIn() && initCompleted
  });

  useEffect(() => {
    if (data && organization.appReferenceId) {
      setConfig(data.clover?.apps[organization.appReferenceId]);
      setIsLoadingConfigData(isLoading);
    }
  }, [data, isLoading, organization]);
  return children;
};

export default ConfigStoreProvider;
