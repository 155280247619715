import { BootstrapConfig } from "@react-awesome-query-builder/bootstrap";
import type { Config } from "@react-awesome-query-builder/bootstrap";

const InitialConfig = BootstrapConfig;

export const filterConfig: Config = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    maxNesting: 1, //* No groups.
    maxNumberOfRules: 6,
    showErrorMessage: true,
    showNot: false,
    clearValueOnChangeOp: true
  },
  fields: {
    firstName: {
      label: "First Name",
      type: "text"
    },
    lastName: {
      label: "Last Name",
      type: "text"
    },
    email: {
      label: "Email",
      type: "text"
    },
    phoneNumber: {
      label: "Phone Number",
      type: "text"
    },
    // avgSpent: {
    //   label: "Average Spent",
    //   type: "number",
    //   valueSources: ["value"],
    //   fieldSettings: {
    //     min: 0,
    //     max: 1000000
    //   },
    //   preferWidgets: ["slider", "rangeslider"]
    // },
    totalTransactionsCount: {
      label: "Visits",
      type: "number",
      valueSources: ["value"]
      // fieldSettings: {
      //   min: 0,
      //   max: 1000000
      // },
      // preferWidgets: ["slider", "rangeslider"]
    },
    "optIn.marketing.allowed": {
      label: "Marketing Allowed",
      type: "boolean",
      operators: ["equal"],
      valueSources: ["value"]
    },
    createdOn: {
      label: "Created On",
      type: "date",
      operators: ["equal", "less", "greater", "between"],
      valueSources: ["value"]
    },
    dateOfBirth: {
      label: "Date of Birth",
      type: "date",
      operators: ["equal", "less", "greater", "between"],
      valueSources: ["value"]
    },
    lastPurchasedDate: {
      label: "Last Activity On",
      type: "date",
      operators: ["equal", "less", "greater", "between"],
      valueSources: ["value"]
    }
  }
};
