import { useState, useCallback } from "react";
import { searchOragnizations } from "../../../services";
import AdvanceSearchOption from "../../../Components/Common/AdvanceSearchOption";
import OrganizationsTable from "./OrganizationsTable";
import { Container } from "reactstrap";
import { TOrganizationModelJSON } from "@shoutout-labs/market_buzz_crm_types";

const SwitchAccount = () => {
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [organizationSearchData, setOrganizationSearchData] = useState<
    TOrganizationModelJSON[]
  >([]);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [skip, setSkip] = useState<number>(0);

  const searchOrganizations = useCallback(
    async (query: string) => {
      const queryObj = {
        limit,
        skip,
        query: query
      };

      const res = await searchOragnizations(queryObj);

      setIsSearchResult(true);
      setOrganizationSearchData(res.items);
      return res.items;
    },
    [limit, skip]
  );

  return (
    <div className="page-content d-flex justify-content-center align-items-center">
      <Container>
        <h1>Switch account</h1>

        <AdvanceSearchOption handleSearch={searchOrganizations} />

        <OrganizationsTable
          searchResults={organizationSearchData as []}
          isSearchResult={isSearchResult}
        />
      </Container>
    </div>
  );
};
export default SwitchAccount;
