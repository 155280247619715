import axios, { AxiosRequestConfig } from "axios";
import { useAuthStore } from "../Store/store";

// const tokenExpired = (token: string | null) => {
//   if (!token) {
//     return true;
//   }

//   const tokenData = JSON.parse(atob(token.split(".")[1]));
//   const expirationTime = tokenData.exp * 1000;
//   const currentTime = Date.now();

//   return currentTime > expirationTime;
// };

const customAxios = axios.create({});

// customAxios.interceptors.request.use(
//   async (config) => {
//     const storedToken = sessionStorage.getItem("accessToken");
//     if (storedToken && !tokenExpired(storedToken)) {
//       config.headers.Authorization = `Bearer ${storedToken}`;
//     } else {
//       useAuthStore.getState().logoutUser();
//       console.warn("Token expired or missing. Redirecting to login...");
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      console.warn("Token expired or missing. Redirecting to login...");
      useAuthStore.getState().logoutUser();
    }
    return Promise.reject(error);
  }
);

const getHeaders = async (): Promise<AxiosRequestConfig> => {
  const headers: AxiosRequestConfig = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${useAuthStore.getState().token}`,
      "x-auth": true
    }
  };

  return headers;
};

const handleErrors = async (response: any) => {
  if (!response.status.toString().startsWith("2")) {
    let errorResponse;
    try {
      errorResponse = response.data;
    } catch (e) {
      throw Error(response.statusText);
    }
    throw Error(errorResponse?.error || "Unknown error occurred");
  }
  return response;
};

const fetchGet = async (url: string) => {
  const config: AxiosRequestConfig = {
    headers: (await getHeaders()).headers
  };
  return customAxios
    .get(url, config)
    .then(handleErrors)
    .then((response) => response.data);
};

const fetchPost = async (url: string, body: any) => {
  const config: AxiosRequestConfig = {
    headers: (await getHeaders()).headers
  };
  return customAxios
    .post(url, body, config)
    .then(handleErrors)
    .then((response) => response.data);
};

const fetchPut = async (url: string, body: any) => {
  const config: AxiosRequestConfig = {
    headers: (await getHeaders()).headers
  };
  return customAxios
    .put(url, body, config)
    .then(handleErrors)
    .then((response) => response.data);
};

const fetchDelete = async (url: string, body: any, track: any) => {
  const config: AxiosRequestConfig = {
    method: "delete",
    headers: (await getHeaders()).headers,
    data: body,
    params: track
  };
  return customAxios(url, config).then(handleErrors);
};

const jsonToQueryParam = (json: { [key: string]: any }): string => {
  let result = "";
  Object.entries(json).forEach(([key, value]) => {
    if (value !== "" && value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((element) => {
          result += `${result ? "&" : ""}${key}[]=${element}`;
        });
      } else {
        result += `${result ? "&" : ""}${key}=${value}`;
      }
    }
  });
  return result;
};

export {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  handleErrors,
  jsonToQueryParam
};
