import React, { useState, useCallback, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { BarLineChart } from "../Common/BarLineChart";
import { DateBucketEnum } from "../../../constants/dateRange";
import Loader from "../../../Common/Loader";
import { useAuthStore, useConfigStore } from "../../../../Store/store";
import moment from "moment";
import {
  getCustomersDateBucketAnalytics,
  getNewCustomerCountAnalytics,
  getRepeatCustomerCountAnalytics,
  getTotalTransactionCountAnalytics,
  getRepeatCustomersDateBucketAnalytics,
  getVisitFrequencyAnalytics
} from "../../../../services";
import { Utility } from "../../../../utils";

const optionsMixedChart = {
  chart: {
    toolbar: {
      show: false
    }
  },
  stroke: {
    width: [0, 4]
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [1]
  },
  xaxis: {
    type: "string"
  },
  yaxis: [
    {
      title: {
        text: "New Customers",
        style: {
          fontWeight: 600
        }
      },
      tickAmount: 2,
      min: 0,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        }
      }
    },
    {
      opposite: true,
      title: {
        text: "Return Customers",
        style: {
          fontWeight: 600
        }
      },
      min: 0,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        }
      }
    }
  ],
  colors: ["#32ccff", "#128f8b"]
};

interface CustomersChartViewProps {
  selectionRange: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}
interface NewCustomerData {
  dateBucketKey: string;
  customerCount: number;
}

interface RepeatCustomerData {
  dateBucketKey: string;
  customerCount: number;
}

interface SeriesData {
  name: string;
  type: "column" | "line";
  data: number[];
}

interface TransformedData {
  seriesMixedChart: SeriesData[];
  labels: string[];
}

const transformData = (
  newCustomerData: NewCustomerData[],
  repeatCustomerData: RepeatCustomerData[],
  startDate: Date,
  endDate: Date,
  interval: DateBucketEnum
): TransformedData => {
  const newCustomers: number[] = [];
  const returnCustomers: number[] = [];
  const labels: string[] = [];

  const newCustomerMap = new Map<string, number>();
  newCustomerData.forEach((item) => {
    newCustomerMap.set(item.dateBucketKey, item.customerCount);
  });

  const repeatCustomerMap = new Map<string, number>();
  repeatCustomerData.forEach((item) => {
    repeatCustomerMap.set(item.dateBucketKey, item.customerCount);
  });

  Utility.iterateDateRange(
    startDate,
    endDate,
    (dateKey: string) => {
      labels.push(dateKey);

      if (newCustomerMap.has(dateKey)) {
        newCustomers.push(newCustomerMap.get(dateKey)!);
      } else {
        newCustomers.push(0);
      }

      if (repeatCustomerMap.has(dateKey)) {
        returnCustomers.push(repeatCustomerMap.get(dateKey)!);
      } else {
        returnCustomers.push(0);
      }
    },
    interval
  );

  const seriesMixedChart: SeriesData[] = [
    {
      name: "New Customers",
      type: "column",
      data: newCustomers
    },
    {
      name: "Repeat Customers",
      type: "line",
      data: returnCustomers
    }
  ];

  return { seriesMixedChart, labels };
};

const CustomersChartView: React.FC<CustomersChartViewProps> = ({
  selectionRange
}) => {
  const [config] = useConfigStore((state) => [state.config]);

  const [selectedFilter, setSelectedFilter] = useState(DateBucketEnum.WEEK);
  const [chartData, setChartData] = useState<SeriesData[]>([]);
  const [chartOptions, setChartOptions] = useState({
    ...optionsMixedChart,
    colors:
      [config?.primaryColor, config?.secondaryColor] || optionsMixedChart.colors // Set default colors
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [newCustomerCount, setNewCustomerCount] = useState(0);
  const [returnCustomerCount, setReturnCustomerCount] = useState(0);
  const [averageVisitFrequency, setAverageVisitFrequency] = useState(0);

  const handleFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const filter = e.target.value;
      setSelectedFilter(filter as DateBucketEnum);
    },
    [setSelectedFilter]
  );

  const loadAnalyticsData = useCallback(async () => {
    try {
      const queryObjSeries = {
        fromDate: moment(selectionRange.startDate).format("YYYY-MM-DD"),
        toDate: moment(selectionRange.endDate).format("YYYY-MM-DD"),
        bucketBy: selectedFilter
      };

      const queryObj = {
        fromDate: moment(selectionRange.startDate).format("YYYY-MM-DD"),
        toDate: moment(selectionRange.endDate).format("YYYY-MM-DD")
      };
      setIsLoading(true);
      setChartData([]);

      const [
        newCustomerAnalyticsRes,
        returnCustomerAnalyticsRes,
        visitFrequencyAnalyticsRes,
        newCustomerBucketRes,
        repeatCustomerBucketRes
      ] = await Promise.all([
        getNewCustomerCountAnalytics(queryObj),
        getRepeatCustomerCountAnalytics(queryObj),
        getVisitFrequencyAnalytics(queryObj),
        getCustomersDateBucketAnalytics(queryObjSeries),
        getRepeatCustomersDateBucketAnalytics(queryObjSeries),
        getTotalTransactionCountAnalytics()
      ]);

      const dataTransformRes = transformData(
        newCustomerBucketRes,
        repeatCustomerBucketRes,
        selectionRange.startDate,
        selectionRange.endDate,
        selectedFilter
      );

      setChartData(dataTransformRes.seriesMixedChart);
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        labels: dataTransformRes.labels
      }));

      setNewCustomerCount(newCustomerAnalyticsRes.count);
      setReturnCustomerCount(returnCustomerAnalyticsRes.count);
      setAverageVisitFrequency(visitFrequencyAnalyticsRes.frequency);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [selectionRange.startDate, selectionRange.endDate, selectedFilter]);

  useEffect(() => {
    if (isLoggedIn()) {
      loadAnalyticsData();
    }
  }, [selectionRange, selectedFilter, isLoggedIn, loadAnalyticsData]);

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1 text-center">
            Customer Summary
          </h4>
        </CardHeader>

        <CardHeader className="p-0 border-0 bg-light-subtle">
          <Row className="g-0 text-center">
            <Col xs={6} sm={4}>
              <div className="p-3 border border-dashed border-start-0">
                <p className="text-muted mb-0">New Customers</p>
                <h3>{newCustomerCount}</h3>
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className="p-3 border border-dashed border-start-0">
                <p className="text-muted mb-0">Repeat Customers</p>
                <h3>{returnCustomerCount}</h3>
              </div>
            </Col>
            <Col xs={6} sm={4}>
              <div className="p-3 border border-dashed border-start-0">
                <p className="text-muted mb-0">Average Visit Frequency</p>
                <h3>{averageVisitFrequency.toFixed(2)}</h3>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <Row>
            <Col xxl={10} xl={10} lg={10} md={10} sm={8}></Col>
            <Col xxl={2} xl={2} lg={2} md={2} sm={4}>
              <div className="form-group mb-0">
                <select
                  className="form-control form-select"
                  onChange={handleFilterChange}
                  value={selectedFilter}
                >
                  <option value={DateBucketEnum.DAY}>Daily</option>
                  <option value={DateBucketEnum.WEEK}>Weekly</option>
                  <option value={DateBucketEnum.QUARTER}>Quaters</option>
                  <option value={DateBucketEnum.MONTH}>Monthly</option>
                  <option value={DateBucketEnum.YEAR}>Yearly</option>
                </select>
              </div>
            </Col>
          </Row>
        </CardBody>
        <div className="card-body px-0 pb-0">
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <div>
              {chartData.length > 0 && (
                <BarLineChart
                  chartType="bar"
                  series={chartData}
                  options={chartOptions}
                  width="100%"
                  height="355"
                />
              )}
            </div>
          )}
        </div>
      </Card>
    </React.Fragment>
  );
};

export default CustomersChartView;
