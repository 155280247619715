import { useCallback, useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Col,
  Badge
} from "reactstrap";
import CustomersTabs from "./CustomersTabs";
import {
  UilUsersAlt,
  UilCalendarAlt,
  UilStore,
  UilShoppingCartAlt,
  UilStepBackward,
  UilSkipForward,
  UilMegaphone,
  UilGift,
  UilEnvelope,
  UilPhone
} from "@iconscout/react-unicons";
import moment from "moment";
import { useCustomersStore } from "../../../Store/store";
import { Utility } from "../../../utils";
import { TCustomerModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import "./UiOffCanvas.scss";

interface UiOffcanvasProps {
  isRight: boolean;
  toggleRightCanvas: (e: any) => void;
  customersResults: TCustomerModelJSON[];
}

const UiOffcanvas = ({
  isRight,
  toggleRightCanvas,
  customersResults
}: UiOffcanvasProps) => {
  const [isDisablePrev, setIsDisablePrev] = useState(false);
  const [isDisableNext, setIsDisableNext] = useState(false);
  const [selectedCusIndex, setSelectedCusIndex] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useCustomersStore((state) => [
    state.selectedCustomer,
    state.setSelectedCustomer
  ]);

  const getPrevCustomer = useCallback(() => {
    if (selectedCusIndex > 0) {
      const currentCustomer = customersResults[selectedCusIndex - 1];
      setSelectedCustomer(currentCustomer);
    }
  }, [customersResults, selectedCusIndex, setSelectedCustomer]);

  const getNextCustomer = useCallback(() => {
    if (selectedCusIndex < customersResults.length - 1) {
      const currentCustomer = customersResults[selectedCusIndex + 1];
      setSelectedCustomer(currentCustomer);
    }
  }, [customersResults, selectedCusIndex, setSelectedCustomer]);

  useEffect(() => {
    try {
      const customerIndex = Utility.getCustomerIndex(
        selectedCustomer.id,
        customersResults
      );

      setSelectedCusIndex(customerIndex);
      setIsDisablePrev(customerIndex === 0);
      setIsDisableNext(customerIndex + 1 === customersResults.length);
    } catch (e) {
      console.error("Error when getting the index of selected member", e);
    }
  }, [selectedCustomer, customersResults]);
  return (
    <>
      <Offcanvas
        isOpen={isRight}
        direction="end"
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        className="border-bottom custom-offcanvas"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          <div className="profile-nav d-flex justify-content-end">
            <UilStepBackward
              className={
                isDisablePrev
                  ? "text-secondary mx-2 nav-icon cursor-disable"
                  : "mx-2 nav-icon"
              }
              onClick={getPrevCustomer}
            />
            <UilSkipForward
              className={
                isDisableNext
                  ? "text-secondary nav-icon cursor-disable"
                  : "nav-icon"
              }
              onClick={getNextCustomer}
            />
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="p-0 overflow-hidden">
          <Row className="mb-2">
            <Col
              xl="3"
              lg="3"
              md="3"
              className="border-solid-right d-flex align-items-center"
            >
              <div className="d-flex flex-row pr-3 ml-1 align-items-center">
                <div className="pr-3 mr-1 ml-1 mt-4">
                  <div className="mx-3">
                    <UilUsersAlt size={40} />
                  </div>
                </div>
                <div className="d-flex flex-column mr-1">
                  <h3 className="mt-3 py-0">
                    {`${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}
                  </h3>
                </div>
              </div>
            </Col>
            <Col
              xl="3"
              lg="3"
              md="3"
              className="border-solid-right d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <p>
                  <UilEnvelope size={15} /> Email
                </p>
                <h6>{selectedCustomer?.email || "-"}</h6>
                <p className="mt-2">
                  <UilPhone size={15} /> Phone Number
                </p>
                <h6>{selectedCustomer?.phoneNumber || "-"}</h6>
              </div>
            </Col>
            <Col
              xl="2"
              lg="2"
              md="2"
              className="border-solid-right d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <p>
                  <UilCalendarAlt size={15} /> Created On
                </p>
                <h6>
                  {moment(
                    selectedCustomer?.customerCreatedOn ||
                      selectedCustomer?.createdOn
                  ).format("LL")}
                </h6>

                <p className="mt-2">
                  <UilCalendarAlt size={15} /> Last Activity On
                </p>
                <h6>
                  {moment(selectedCustomer?.lastPurchasedDate).format("LL")}
                </h6>
              </div>
            </Col>
            <Col
              xl="2"
              lg="2"
              md="2"
              className="border-solid-right d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <p>
                  <UilGift size={15} /> Date of Birth
                </p>
                <h6>
                  {moment(selectedCustomer?.dateOfBirth).format("LL") ||
                    "Not available"}
                </h6>

                <p className="mt-2">
                  <UilMegaphone size={15} /> Marketing Permission
                </p>
                <h6>
                  {selectedCustomer?.optIn?.marketing?.allowed ? (
                    <Badge color="primary" pill>
                      Allowed
                    </Badge>
                  ) : (
                    <Badge color="danger" pill>
                      Denied
                    </Badge>
                  )}
                </h6>
              </div>
            </Col>
            <Col
              xl="2"
              lg="2"
              md="2"
              className="border-solid-right d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <p>
                  <UilStore size={15} /> Visits
                </p>
                <h6>{selectedCustomer?.totalTransactionsCount}</h6>

                <p className="mt-2">
                  <UilShoppingCartAlt size={15} /> Avg. Spend
                </p>
                <h6>
                  ${" "}
                  {Utility.getAvgSpend(
                    (selectedCustomer?.totalTransactionsCount as number) || 1,
                    (selectedCustomer?.totalTransactionsSum as number) || 0
                  )}
                </h6>
              </div>
            </Col>
          </Row>
          <CustomersTabs />
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default UiOffcanvas;
