import React, { useCallback } from "react";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";
// import { Link } from "react-router-dom";
import Constants from "../../Constants";

const Login = (props: any) => {
  const handleLoginClover = useCallback(() => {
    window.location.href = `${Constants.REACT_APP_API_BASE_URL}auth/clover/login`;
  }, []);

  const handleLogin = useCallback(() => {
    window.location.href = `${Constants.REACT_APP_API_BASE_URL}auth/login`;
  }, []);
  return (
    <React.Fragment>
      <div className="auth-page-content">
        <Container style={{ marginTop: "10%" }}>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5} className="pt-4 mb-3">
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">Sign in to continue.</p>
                  </div>
                  <div className="p-2 mt-4">
                    <div className="mt-4">
                      <Button
                        color="primary"
                        className="btn btn-primary w-100"
                        onClick={handleLogin}
                      >
                        Sign In
                      </Button>
                    </div>
                    <div className="mt-4">
                      <Button
                        color="primary"
                        className="btn btn-primary w-100"
                        onClick={handleLoginClover}
                      >
                        Sign In with Clover
                      </Button>
                      <small className="text-muted mt-3 text-justify">
                        <strong>Having trouble signing in?</strong>
                        Try signing in directly through Clover.
                      </small>
                    </div>
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-4 text-center">
                <p className="mb-0">
                  Don't have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-semibold text-primary text-decoration-underline"
                  >
                    {" "}
                    Signup{" "}
                  </Link>
                </p>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
