import { create } from "zustand";
import {
  TWebsocketSyncDataResponse,
  TCustomerModelJSON,
  TOrganizationModelJSON,
  TCloverAppsSchemaWithoutAppSecret
  // UserRole
} from "@shoutout-labs/market_buzz_crm_types";
import { Utility } from "../utils";
import { UserRole } from "../Constants";
import { Features } from "../Components/constants/layout";
import GTMService from "../services/GTMService";
import {
  CustomersFilterTasks,
  CustomerFilterBaseQuery,
  SearchCustomerQuery,
  FilterCustomerQuery
} from "./../Components/Modules/Customers/context/CustomerDataContext";

interface CustomerQueryTask {
  task: CustomersFilterTasks;
  query: CustomerFilterBaseQuery | SearchCustomerQuery | FilterCustomerQuery;
}

const defaultCloverAppConfig: TCloverAppsSchemaWithoutAppSecret = {
  features: [Features.CRM, Features.ANALYTICS],
  hasSubscriptionPackages: false,
  legacySubscriptionJobEnabled: false,
  appName: "Market Buzz",
  logo: "",
  primaryColor: "#128f8b",
  primaryHoverColor: "#0f7a76",
  secondaryColor: "#878a99"
};

type SideBarStore = {
  sidebarVisibilityType: string;
  changeSidebarVisibility: (sidebarVisibilitytype: string) => void;
  layoutType: string;
  leftSidebarType: string;
  layoutModeType: string;
  layoutWidthType: string;
  layoutPositionType: string;
  topbarThemeType: string;
  leftsidbarSizeType: string;
  leftSidebarViewType: string;
};

type AuthStore = {
  user: object;
  setUser: (user: object) => void;
  token: string;
  role: string;
  organizationId: string;
  initCompleted: boolean;
  setToken: (token: string) => void;
  logoutUser: () => void;
  isLoggedIn: () => boolean;
};

type CustomersStore = {
  totalCustomers: number;
  setTotalCustomers: (totalCustomers: number) => void;
  customerList: TCustomerModelJSON[];
  setCustomerList: (customerList: TCustomerModelJSON[]) => void;
  selectedCustomer: TCustomerModelJSON;
  setSelectedCustomer: (selectedCustomer: TCustomerModelJSON) => void;
  segments: [];
  setSegments: (segments: []) => void;
  limit: number;
  setLimit: (limit: number) => void;
  skip: number;
  setSkip: (skip: number) => void;
  queryTask: CustomerQueryTask;
  setQueryTask: (queryTask: CustomerQueryTask) => void;
};

type OrganizationStore = {
  credits: number;
  setCredits: (credits: number) => void;
  dollarValue: number;
  setDollarValue: (dollarValue: number) => void;
  organization: TOrganizationModelJSON;
  setOrganization: (organization: TOrganizationModelJSON) => void;
  syncDataResponse: TWebsocketSyncDataResponse;
  setSyncDataResponse: (syncDataResponse: TWebsocketSyncDataResponse) => void;
};

type ConfigStore = {
  config: TCloverAppsSchemaWithoutAppSecret;
  setConfig: (config: TCloverAppsSchemaWithoutAppSecret) => void;
  isLoadingConfigData: boolean;
  setIsLoadingConfigData: (isLoadingConfigData: boolean) => void;
};

export const useSideBarVisibilityStore = create<SideBarStore>((set) => ({
  sidebarVisibilityType: "show",
  changeSidebarVisibility: (sidebarVisibilitytype: string) => {
    Utility.changeHTMLAttribute(
      "data-sidebar-visibility",
      sidebarVisibilitytype
    );
  },
  layoutType: "vertical",
  leftSidebarType: "light",
  layoutModeType: "light",
  layoutWidthType: "lg",
  layoutPositionType: "fixed",
  topbarThemeType: "light",
  leftsidbarSizeType: "lg",
  leftSidebarViewType: "default"
}));

export const useAuthStore = create<AuthStore>((set, get) => ({
  user: {
    name: "Unknown"
  },
  setUser: (user: object) => set(() => ({ user })),
  token: "",
  role: UserRole.USER,
  organizationId: "",
  initCompleted: false,
  setToken: (token: string) => {
    if (token) {
      const decodedToken = Utility.decodeToken(token);

      set(() => ({
        token,
        role: decodedToken.user.role,
        organizationId: decodedToken.user.organizationId,
        initCompleted: true
      }));
    } else {
      set({ token: "", initCompleted: true });
    }
  },
  logoutUser: () => {
    sessionStorage.removeItem("accessToken");
    set({ token: "", initCompleted: true });
    GTMService.logoutEvent({
      organizationId: get().organizationId,
      role: get().role
    });
    // window.location.href = `${Constants.REACT_APP_API_BASE_URL}auth/clover/login`;
  },
  isLoggedIn: () => !!get().token
}));

export const useCustomersStore = create<CustomersStore>((set) => ({
  totalCustomers: 0,
  setTotalCustomers: (totalCustomers: number) =>
    set(() => ({
      totalCustomers
    })),
  customerList: [] as TCustomerModelJSON[],
  setCustomerList: (customerList: TCustomerModelJSON[]) =>
    set(() => ({
      customerList
    })),
  selectedCustomer: {} as TCustomerModelJSON,
  setSelectedCustomer: (selectedCustomer: TCustomerModelJSON) =>
    set(() => ({
      selectedCustomer
    })),
  segments: [],
  setSegments: (segments: []) =>
    set(() => ({
      segments
    })),
  limit: 50,
  setLimit: (limit: number) =>
    set(() => ({
      limit
    })),
  skip: 0,
  setSkip: (skip: number) =>
    set(() => ({
      skip
    })),
  queryTask: { task: CustomersFilterTasks.getAllCustomers, query: {} },
  setQueryTask: (queryTask: CustomerQueryTask) =>
    set(() => ({
      queryTask
    }))
}));

export const useOrganizationStore = create<OrganizationStore>((set) => ({
  credits: 0,
  setCredits: (credits: number) => set({ credits }),
  dollarValue: 0,
  setDollarValue: (dollarValue: number) => set({ dollarValue }),
  organization: {} as TOrganizationModelJSON,
  setOrganization: (organization: TOrganizationModelJSON) =>
    set(() => ({
      organization
    })),
  syncDataResponse: { customer: 0, orders: 0 } as TWebsocketSyncDataResponse,
  setSyncDataResponse: (syncDataResponse: TWebsocketSyncDataResponse) =>
    set(() => ({
      syncDataResponse
    }))
}));

export const useConfigStore = create<ConfigStore>((set) => ({
  config: defaultCloverAppConfig,
  setConfig: (config: TCloverAppsSchemaWithoutAppSecret) =>
    set(() => ({
      config
    })),
  isLoadingConfigData: false,
  setIsLoadingConfigData: (isLoadingConfigData: boolean) =>
    set(() => ({
      isLoadingConfigData
    }))
}));
