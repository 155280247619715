import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { UilUsersAlt } from "@iconscout/react-unicons";
import { useAuthStore } from "../../../Store/store";
import { useNavigate } from "react-router-dom";
import { useOrganizationStore } from "../../../Store/store";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const [logoutUser] = useAuthStore((state) => [state.logoutUser]);
  const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
  const [organization] = useOrganizationStore((state) => [state.organization]);

  const toggleProfileDropdown = useCallback(() => {
    setIsProfileDropdown(!isProfileDropdown);
  }, [isProfileDropdown, setIsProfileDropdown]);

  const onLogoutUser = useCallback(() => {
    logoutUser();
    navigate("/login");
  }, [logoutUser, navigate]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <UilUsersAlt />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {organization?.name || "Admin"}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {organization?.name || "Admin"}!
          </h6>
          <DropdownItem className="p-0">
            <Link to="/profile" className="dropdown-item">
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </Link>
          </DropdownItem>
          <DropdownItem onClick={onLogoutUser}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
