import { useState } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Common/BreadCrumb";
import { DateRangeSelector } from "../../../Common/DateRangeSelector";
import CustomersCount from "./CustomerCount";
import CustomersChartView from "./CustomersChartView";
import { Utility } from "../../../../utils";

const CustomersReportPage = () => {
  document.title = "Reports - Customers | MarketBuzz";

  const [selectionRange, setSelectionRange] = useState(
    Utility.defaultDateRange()
  );

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Customers" pageTitle="Reports" />
          <CustomersCount />
          <DateRangeSelector
            setSelectionRange={setSelectionRange}
            selectionRange={selectionRange}
          />
          <CustomersChartView selectionRange={selectionRange} />
        </Container>
      </div>
    </>
  );
};

export default CustomersReportPage;
