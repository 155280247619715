import { Col, Row } from "reactstrap";
import StatCard from "../../../Common/StatCard";
import { useCustomersStore } from "../../../../Store/store";

const CustomersCount = () => {
  const customerCountAllTime = useCustomersStore(
    (state) => state.totalCustomers
  );

  return (
    <>
      <div className="my-2">
        <Row className="project-wrapper">
          <Col xxl={3} xl={4} lg={4} md={4} sm={5}>
            <StatCard
              title="Total Customers"
              count={customerCountAllTime}
              isCurrency={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CustomersCount;
