import React, { useState, useCallback } from "react";
import { Button, Input } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import { TOrganizationModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import Loader from "./Loader";

const AdvanceSearchOption = ({
  handleSearch
}: {
  handleSearch: (value: string) => Promise<TOrganizationModelJSON[]>;
}) => {
  const [value, setValue] = useState("");

  const searchOrganizations = useCallback(async () => {
    return handleSearch(value);
  }, [handleSearch, value]);

  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const { data, isLoading, error, refetch } = useQuery<
    TOrganizationModelJSON[]
  >({
    queryKey: ["searchOrganizations", value],
    queryFn: searchOrganizations,
    enabled: false,
    refetchOnWindowFocus: false
  });

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        refetch();
      }
    },
    [refetch]
  );

  return (
    <React.Fragment>
      <form
        className="app-search d-none d-md-block"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="position-relative d-flex align-items-center">
          <Input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <span className="mdi mdi-magnify search-widget-icon"></span>
          <Button className="ml-auto" color="primary" onClick={() => refetch()}>
            Search
          </Button>
        </div>
        {isLoading && <Loader />}
      </form>
    </React.Fragment>
  );
};

export default AdvanceSearchOption;
