import { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import TransactionsTable from "../../../Components/Modules/Customers/TransactionsTable";

const CusomtersTabs = () => {
  const [activeTab, setactiveTab] = useState("1");
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs mb-3">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Transactions
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Segments
                  </NavLink>
                </NavItem> */}
              </Nav>

              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1" id="transactions">
                  <TransactionsTable />
                </TabPane>
                {/* <TabPane tabId="2" id="segments">
                  
                </TabPane> */}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CusomtersTabs;
