import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  Tooltip
} from "reactstrap";
import { createCampaign } from "../../../services";
import {
  CampaignStatus,
  CampaignChannel,
  CampaignType
} from "../../../Components/constants/campaign";
import { ContentState, EditorState } from "draft-js";
import MessageTextArea from "../../../pages/BaseUi/UiModals/MessageTextArea";
import BottomContainer from "../../../pages/BaseUi/UiModals/BottomContainer";
import SMSUtils from "@shoutout-labs/marketbuzz-utils/dist/messages/sms.utils";
import { useAuthStore } from "../../../Store/store";
import { Utility } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import {
  getSegments,
  filterCustomersCount,
  getCustomersCount
} from "../../../services";

import { useMutation } from "@tanstack/react-query";
import { TCampaignCreateRequest } from "@shoutout-labs/market_buzz_crm_types";
import { toast } from "react-toastify";
import { PreDefinedSegments } from "../Customers/Constants";
import CustomerSegmentDropdown from "../Customers/CustomerSegmentDropdown";
import { filterConfig } from "../Customers/CustomersFilterConfig";
import { UilInfoCircle } from "@iconscout/react-unicons";
import "./CampaignCreateModal.scss";

interface UiCampaignEditModalProps {
  fade: boolean | undefined;
  showModal: boolean;
  closeModal: () => void;
}

const CampaignCreateModal = ({
  fade,
  showModal,
  closeModal
}: UiCampaignEditModalProps) => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedSegmentName, setSelectedSegmentName] = useState("");
  const [limitSegments, setLimitSegments] = useState(100);
  const [skipSegments, setSkipSegments] = useState(0);
  const [customersCountOfSegment, setCustomersCountOfSegment] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [messageError, setMessageError] = useState(true);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(message))
  );
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);

  const handleInputChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawText = contentState.getPlainText();
    setMessage(rawText);
    if (rawText.trim()) {
      setMessageError(false);
    } else {
      setMessageError(true);
    }
  };

  const charCount = useMemo(() => message.length, [message]);

  const pageCount = useMemo(
    () => SMSUtils.getPagesCount(message, 20),
    [message]
  );

  const mutation = useMutation({
    mutationFn: (payload: TCampaignCreateRequest) => {
      return createCampaign(payload);
    },
    onSuccess: () => {
      toast.success("Campaign is created");
      closeModal();
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const loadSegmentsData = () => {
    const queryObj = {
      limit: limitSegments,
      skip: skipSegments
    };
    return getSegments(queryObj);
  };

  const getCustomersCountInSegment = useCallback(
    async (filterObj) => {
      const res = await filterCustomersCount({ filterObj });
      setCustomersCountOfSegment(res.count ?? 0);
    },
    [setCustomersCountOfSegment]
  );

  const { data: segmentsData, isLoading } = useQuery<any>({
    queryKey: ["segments", skipSegments],
    queryFn: loadSegmentsData,
    enabled: isLoggedIn(),
    refetchOnWindowFocus: false
  });

  const { data: totalCustomers, isLoading: isLoadingTotalCustomers } =
    useQuery<number>({
      queryKey: ["customerCount", selectedSegment == "all_customers"],
      queryFn: async () => {
        const queryObj = {
          marketingAllowed: true,
          isRequiredPhoneNumber: true
        };
        const response = await getCustomersCount(queryObj);
        return response?.count;
      },
      enabled: isLoggedIn,
      refetchOnWindowFocus: false
    });

  const allSegments = useMemo(() => {
    if (!isLoading && segmentsData?.items.length > 0) {
      return [...PreDefinedSegments, ...segmentsData?.items];
    } else {
      return [...PreDefinedSegments];
    }
  }, [segmentsData, isLoading]);

  const onChangeCampaignName = useCallback(
    (e) => setName(e.target.value),
    [setName]
  );

  const onSelectSegment = useCallback(
    (segmentId) => {
      setSelectedSegment(segmentId);
      if (segmentId == "all_customers") {
        setCustomersCountOfSegment(totalCustomers);
        setSelectedSegmentName("All Customers");
      } else {
        const segmentData = allSegments?.find(
          (segment) => segment?.id === segmentId
        );
        setSelectedSegmentName(segmentData?.name);
        const customerFilters = Utility.getMongoDBQuery(
          segmentData?.filter,
          filterConfig
        );
        getCustomersCountInSegment(customerFilters);
      }
    },
    [
      setSelectedSegment,
      setSelectedSegmentName,
      allSegments,
      setCustomersCountOfSegment,
      totalCustomers
    ]
  );

  const handleCampaignSent = useCallback(async () => {
    let payload: {
      content: string;
      name: string;
      channel: CampaignChannel;
      status: CampaignStatus;
      type: CampaignType;
      segmentId?: string;
      filter?: object;
    } = {
      content: message,
      name: name,
      channel: CampaignChannel.SMS,
      status: CampaignStatus.DRAFT,
      type: CampaignType.BROADCAST
    };
    if (selectedSegment !== "all_customers") {
      const segmentData = allSegments?.find(
        (segment) => segment?.id === selectedSegment
      );
      const customerFilters = Utility.getMongoDBQuery(
        segmentData?.filter,
        filterConfig
      );
      payload.segmentId = selectedSegment;
      payload.filter = customerFilters;
    }
    mutation.mutate(payload);
  }, [message, name, mutation, selectedSegment, allSegments]);

  const formIsValid = name.trim() && message.trim();

  return (
    <div>
      <div>
        <Modal isOpen={showModal} toggle={closeModal} fade={fade}>
          <ModalHeader toggle={closeModal} color="primary">
            Create New Campaign
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Camapign Name
                <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter a filter segment name"
                value={name}
                onChange={onChangeCampaignName}
                required
              />
              {name === "" && (
                <span className="text-danger">
                  * Campaign name cannot be empty!
                </span>
              )}
            </FormGroup>
            <FormGroup>
              <Label>
                Customer Segment
                <span className="text-danger">*</span>
              </Label>
              <CustomerSegmentDropdown
                allSegments={allSegments}
                toggleTab={onSelectSegment}
                isAllCustomers={true}
                title="Select Customer Segment"
              />
              {selectedSegmentName === "" && (
                <span className="text-danger">
                  * Please select a customer segement!
                </span>
              )}
              {selectedSegmentName && (
                <Card>
                  <CardBody>
                    <p>
                      Selected segment:
                      <span className="fw-bold"> {selectedSegmentName}</span>
                    </p>
                    <p className="my-0 py-0">
                      No. of Customers:
                      <span className="fw-bold">
                        {" "}
                        {customersCountOfSegment}
                      </span>
                      <span className="mx-2">
                        <UilInfoCircle size={15} id="TooltipInfo" />
                        <Tooltip
                          placement="right"
                          isOpen={tooltipOpen}
                          target="TooltipInfo"
                          toggle={toggle}
                          className="custom-tooltip tooltip-inner"
                        >
                          <p className="text-info text-justify">
                            SMS campaigns are sent only to customers who have
                            provided a mobile number and opted in for marketing.
                          </p>
                        </Tooltip>
                      </span>
                    </p>
                  </CardBody>
                </Card>
              )}
            </FormGroup>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <Label>
                Message Text
                <span className="text-danger">*</span>
              </Label>
            </div>
            <MessageTextArea
              editorState={editorState}
              handleInputChange={handleInputChange}
            />
            {messageError && (
              <span className="text-danger">* Message cannot be empty!</span>
            )}
            <BottomContainer
              charCount={charCount}
              pageCount={pageCount}
              message={message}
              isShowCustomAttributeNotification={false}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleCampaignSent}
              disabled={
                !formIsValid ||
                Utility.hasCustomAttributes(message) ||
                pageCount > 3 ||
                mutation.isPending
              }
            >
              {mutation.isPending ? "Creating..." : "Start Campaign Now"}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={closeModal}
              disabled={mutation.isPending}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default CampaignCreateModal;
