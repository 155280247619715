import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import StatCard from "../../../Common/StatCard";
import {
  getTotalTransactionCountAnalytics,
  getTotalSalesSumAnalytics
} from "../../../../services";
import {
  TTransactionCountReportResponse,
  TSalesSumResponse
} from "@shoutout-labs/market_buzz_crm_types";
import { useAuthStore } from "../../../../Store/store";

const SalesCount = () => {
  const [totalSales, setTotalSales] = useState<TSalesSumResponse>({
    sum: 0,
    compareToSum: 0,
    comparisonPercentage: 0
  });
  const [totalTransactions, setTotalTransactions] =
    useState<TTransactionCountReportResponse>({
      count: 0,
      compareToCount: 0,
      comparisonPercentage: 0
    });
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);

  const loadAnalyticsData = useCallback(async () => {
    try {
      const [transactionRes, salesSumRes] = await Promise.all([
        getTotalTransactionCountAnalytics(),
        getTotalSalesSumAnalytics()
      ]);

      setTotalSales(salesSumRes);
      setTotalTransactions(transactionRes);
    } catch (e) {
      console.error("Error when loading sales analytics", e);
    }
  }, [setTotalSales, setTotalTransactions]);

  useEffect(() => {
    if (isLoggedIn()) {
      loadAnalyticsData();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      <div className="my-2">
        <Row className="project-wrapper">
          <Col xxl={3} xl={4} lg={4} md={4} sm={5}>
            <StatCard
              title="Total Sales"
              count={totalSales.sum as number}
              isCurrency={true}
            />
          </Col>
          <Col xxl={3} xl={4} lg={4} md={4} sm={5}>
            <StatCard
              title="Total Transaction Count"
              count={totalTransactions.count as number}
              isCurrency={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SalesCount;
