import { useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  ModalFooter,
  Label,
  FormText,
  Input
} from "reactstrap";
import { createSegments } from "../../../services";

const CustomerSegementCreate = ({
  show,
  onHide,
  currentFilters,
  handleSegmentCreationSuccess
}) => {
  const [isSavingFilter, setIsSavingFilter] = useState(false);
  const [segmentName, setSegmentName] = useState("");

  const onChangeSegmentName = useCallback(
    (e) => setSegmentName(e.target.value),
    [setSegmentName]
  );

  const onSaveFilter = useCallback(
    async (event) => {
      try {
        event.preventDefault();
        event.stopPropagation();
        setIsSavingFilter(true);
        const payload = {
          name: segmentName,
          filter: currentFilters
        };

        await createSegments(payload);
        setIsSavingFilter(false);
        onHide();
        handleSegmentCreationSuccess();
      } catch (e) {
        console.error(e);
        setIsSavingFilter(false);
      }
    },
    [
      currentFilters,
      segmentName,
      onHide,
      setIsSavingFilter,
      handleSegmentCreationSuccess
    ]
  );

  return (
    <>
      <Modal
        isOpen={show}
        toggle={onHide}
        backdrop={true}
        unmountOnClose={true}
        centered
      >
        <ModalHeader closeButton={!isSavingFilter}>Create Segment</ModalHeader>
        <Form noValidate onSubmit={onSaveFilter}>
          <ModalBody>
            <FormGroup>
              <Label>
                Filter Segment Name
                <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter a filter segment name"
                value={segmentName}
                disabled={isSavingFilter}
                onChange={onChangeSegmentName}
                required
              />
              {segmentName === "" && (
                <FormText className="text-danger">
                  * Filter segment name cannot be empty!
                </FormText>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-end align-items-center w-100">
              <Button
                className="mx-2"
                color="outline-danger"
                size="sm"
                onClick={onHide}
                disabled={isSavingFilter}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                size="sm"
                disabled={isSavingFilter}
              >
                {isSavingFilter ? "Creating..." : "Create Segment"}
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CustomerSegementCreate;
