import { useEffect, ReactElement } from "react";
import { useAuthStore, useCustomersStore } from "../store";
import { getCustomersCount } from "../../services";
import { useQuery } from "@tanstack/react-query";

const CustomerStoreProvider = ({ children }: { children: ReactElement }) => {
  const [isLoggedIn, initCompleted] = useAuthStore((state) => [
    state.isLoggedIn,
    state.initCompleted
  ]);
  const [setTotalCustomers] = useCustomersStore((state) => [
    state.setTotalCustomers
  ]);

  const { data } = useQuery({
    queryKey: ["customersCount"],
    queryFn: () => getCustomersCount({}),
    enabled: isLoggedIn() && initCompleted
  });

  useEffect(() => {
    if (data) {
      setTotalCustomers(data.count);
    }
  }, [data]);
  return children;
};

export default CustomerStoreProvider;
