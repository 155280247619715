import { useState, useCallback } from "react";
import PropTypes from "prop-types";

const useToggle = (props) => {
  const [state, setState] = useState(props);

  return [
    state,
    useCallback(() => {
      setState((state) => !state);
    }, [])
  ];
};

useToggle.defaultProps = {
  props: false
};
useToggle.propTypes = {
  props: PropTypes.bool.isRequired
};
export default useToggle;
