import { Card, CardBody, Row, Col } from "reactstrap";
import { UilEnvelopeCheck } from "@iconscout/react-unicons";
import { useOrganizationStore } from "../../../Store/store";

const BillingInfo = () => {
  document.title = "Billing | MarketBuzz";
  const [credits, dollarValue] = useOrganizationStore((state) => [
    state.credits,
    state.dollarValue
  ]);
  return (
    <div className="mt-4">
      <Card className="border shadow-none">
        <CardBody>
          <Row>
            <Col xl={10} l={10} md={10} sm={9}>
              <div className="d-flex align-items-center">
                <h6 className="my-1">Available Buzz Credits</h6>
                <h4 className="mx-3 my-1">{credits}</h4>
              </div>
              <div className="d-flex align-items-center">
                <h5 className="my-2">${parseFloat(dollarValue.toFixed(2))}</h5>
                <h6 className="my-1 mx-1">worth credits</h6>
              </div>
            </Col>
            <Col xl={2} l={2} md={2} sm={3}>
              <div className="d-flex align-items-center my-3">
                <UilEnvelopeCheck size={20} />
                <p className="my-1 mx-1">1 credit per SMS</p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default BillingInfo;
