import React, { useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

const Pagination = ({
  dataCount,
  currentPage,
  setCurrentPage,
  perPageData,
  setSkip,
  loadData
}: any) => {
  const pageNumbers = useMemo(() => {
    const numbers = [];
    for (let i = 1; i <= Math.ceil(dataCount / perPageData); i++) {
      numbers.push(i);
    }
    return numbers;
  }, [dataCount, perPageData]);

  const handleClick = useCallback(
    (pageNumber: number) => {
      try {
        setCurrentPage(pageNumber);
        const skipCount = perPageData * (pageNumber - 1);
        //TODO: Remove skip and use current page
        setSkip(skipCount);
        loadData();
      } catch (e) {
        console.error("Error in pagination", e);
      }
    },
    [setCurrentPage, setSkip, loadData, perPageData]
  );

  const handleprevPage = useCallback(() => {
    try {
      const prevPage = currentPage - 1;
      const skipCount = perPageData * (prevPage - 1);
      setCurrentPage(prevPage);
      setSkip(skipCount);
      loadData();
    } catch (e) {
      console.error("Error in pagination", e);
    }
  }, [setCurrentPage, setSkip, loadData, perPageData, currentPage]);

  const handlenextPage = useCallback(() => {
    try {
      const nextPage = currentPage + 1;
      const skipCount = perPageData * (nextPage - 1);
      setCurrentPage(nextPage);
      setSkip(skipCount);
      loadData();
    } catch (e) {
      console.error("Error in pagination", e);
    }
  }, [setCurrentPage, setSkip, loadData, perPageData, currentPage]);

  useEffect(() => {
    if (pageNumbers.length && pageNumbers.length < currentPage) {
      setCurrentPage(pageNumbers.length);
    }
  }, [pageNumbers.length, currentPage, setCurrentPage]);

  // Determine the visible page range
  const maxVisiblePages = 10;
  const startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
  const endPage = Math.min(startPage + maxVisiblePages - 1, pageNumbers.length);

  const visiblePageNumbers = pageNumbers.slice(startPage - 1, endPage);

  return (
    <React.Fragment>
      <Row className="g-0 justify-content-end mb-4">
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0">
            {currentPage <= 1 ? (
              <Link className="page-link" to="#!">
                ←
              </Link>
            ) : (
              <li className="page-item">
                <Link to="#!" className="page-link" onClick={handleprevPage}>
                  Previous
                </Link>
              </li>
            )}
            {visiblePageNumbers.map((item, key) => (
              <li key={key} className="page-item">
                <Link
                  to="#!"
                  className={
                    currentPage === item ? "page-link active" : "page-link"
                  }
                  onClick={() => handleClick(item)}
                >
                  {item}
                </Link>
              </li>
            ))}
            {currentPage >= pageNumbers.length ? (
              <Link className="page-item pagination-next disabled" to="#!">
                Next
              </Link>
            ) : (
              <li className="page-item">
                <Link to="#!" className="page-link" onClick={handlenextPage}>
                  →
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default Pagination;
