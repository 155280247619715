import { UilUsersAlt } from "@iconscout/react-unicons";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useOrganizationStore } from "../../../Store/store";
import moment from "moment";

const ProfilePage = () => {
  document.title = "Profile | ShoutOUT";
  const [organization] = useOrganizationStore((state) => [state.organization]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <UilUsersAlt />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">
                          Business Name: {organization?.name}
                        </p>
                        <p className="mb-2">
                          Address: {organization?.address?.address1},{" "}
                          {organization?.address?.city},{" "}
                          {organization?.address?.country},{" "}
                          {organization?.address?.state},{" "}
                          {organization?.address?.zip}.
                        </p>
                        <p className="mb-2">
                          Created date and time:{" "}
                          {moment(organization?.createdOn).format("LL LTS")}
                        </p>
                        <p className="mb-2">
                          Updated date and time:{" "}
                          {moment(organization?.updatedOn).format("LL LTS")}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfilePage;
