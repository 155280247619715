import { useState, useEffect, useCallback } from "react";
import { Table } from "reactstrap";
import Pagination from "../../Common/Pagination";
import { useAuthStore, useCustomersStore } from "../../../Store/store";
import { TTransactionModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { getTransactions, getTransactionCount } from "../../../services";
import moment from "moment";
import Loader from "../../Common/Loader";
import "./CustomerTable.scss";

const TransactionsTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(0);
  const [transactions, setTransactions] = useState<TTransactionModelJSON[]>([]);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCustomer] = useCustomersStore((state) => [
    state.selectedCustomer
  ]);

  const loadTransactionData = useCallback(
    async (customerId: string) => {
      try {
        setLoading(true);
        const queryObj = {
          query: customerId
        };
        const getTransactionsQueryObj = {
          limit: limit as number,
          skip: skip as number,
          customerId
        };
        const [transactionsRes, transactionCountRes] = await Promise.all([
          getTransactions(getTransactionsQueryObj),
          getTransactionCount(queryObj)
        ]);
        setTransactions(transactionsRes.items);
        setTotalTransactions(transactionCountRes.count);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [limit, skip, setTransactions, setTotalTransactions]
  );

  useEffect(() => {
    if (isLoggedIn() && selectedCustomer) {
      loadTransactionData(selectedCustomer.id);
    }
  }, [isLoggedIn, selectedCustomer, loadTransactionData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="table-responsive table-card">
            <Table className="align-middle table-nowrap mb-0 customer-table">
              <thead className="table-light">
                <tr>
                  <th scope="col">Transaction ID</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                </tr>
              </thead>
              <tbody>
                {transactions && transactions.length > 0 ? (
                  transactions.map((transaction) => (
                    <tr key={transaction.id} className="">
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <div className="flex-grow-1">{transaction?.id}</div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <div className="flex-grow-1">
                            {transaction?.amount}
                          </div>
                        </div>
                      </td>
                      <td>
                        {" "}
                        {moment(transaction?.transactionOn).format("LL LTS")}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="mt-5">
                    <td className="text-center" colSpan={4}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
            <div className="col-sm">
              <div className="text-muted">
                Showing{" "}
                <span className="fw-semibold"> {transactions.length} </span> of{" "}
                <span className="fw-semibold">{totalTransactions}</span> Results
              </div>
            </div>
            <Pagination
              dataCount={totalTransactions}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPageData={limit}
              setSkip={setSkip}
              loadCustomerData={loadTransactionData}
            />
          </div>
        </>
      )}
    </>
  );
};

export default TransactionsTable;
