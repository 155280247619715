import React from "react";
import { Editor, EditorState } from "draft-js";

interface MessageTextAreaProps {
  editorState: EditorState;
  handleInputChange: (newEditorState: EditorState) => void;
}

const MessageTextArea = ({
  editorState,
  handleInputChange
}: MessageTextAreaProps) => (
  <div
    style={{
      minHeight: "150px",
      border: "1px solid #ddd",
      cursor: "text",
      padding: "16px",
      borderRadius: "2px",
      boxShadow: "inset 0px 1px 8px -3px #ABABAB"
    }}
  >
    <Editor editorState={editorState} onChange={handleInputChange} />
  </div>
);

export default MessageTextArea;
