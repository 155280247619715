import { useCallback, useState } from "react";
import { Container, Row, Button } from "reactstrap";
import CustomersTable from "./CustomersTable";
import SearchOption from "../../Common/SearchOption";
import CustomersFilter from "./CustomersFilter";
import { useToggle } from "../../../customHooks";
import { Utility } from "../../../utils";
import Loader from "../../Common/Loader";
import { filterConfig } from "./CustomersFilterConfig";
import { CustomersFilterTasks } from "./context/CustomerDataContext";
import "./CustomerPage.scss";

const AllCustomerTabComponent = ({
  handleSegmentCreationSuccess,
  limit,
  skip,
  setQueryTask,
  customersList,
  isLoadingCustomers,
  isFetchingCustomersData,
  customersCount,
  isLoadingCustomersCount,
  isFetchingCustomersCount
}) => {
  document.title = "All Customer Tab Component | MarketBuzz";

  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [isFilterResult, setIsFilterResult] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useCallback(
    async (value: string) => {
      if (!value.trim()) return;

      try {
        const queryObjSearch = { query: value };
        setIsSearchResult(true);
        setQueryTask({
          task: CustomersFilterTasks.searchCustomers,
          query: queryObjSearch
        });
      } catch (error) {
        console.error("Error searching customers:", error);
      }
    },
    [setQueryTask]
  );

  const onFilterCustomers = useCallback(
    async (filters) => {
      const customerFilters = Utility.getMongoDBQuery(filters, filterConfig);
      try {
        setQueryTask({
          task: CustomersFilterTasks.filterCustomers,
          query: { filterObj: customerFilters }
        });
      } catch (e) {
        console.error("Error filtering customers:", e);
      }
    },
    [limit, skip, setQueryTask]
  );

  const isLoading =
    isLoadingCustomers ||
    isLoadingCustomersCount ||
    isFetchingCustomersCount ||
    isFetchingCustomersData;

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex flex-row justify-content-between">
          <div className="search-div">
            <SearchOption
              handleSearch={handleSearch}
              setSearchValue={setSearchValue}
            />
          </div>
          <div>
            <Button
              color="outline-primary"
              className="mx-3"
              onClick={toggleShowFilters}
            >
              {showFilters ? "Hide Filters" : "Filter Customers"}
            </Button>
          </div>
        </div>

        {showFilters && (
          <CustomersFilter
            isLoading={isLoading}
            setIsFilterResult={setIsFilterResult}
            onFilterCustomers={onFilterCustomers}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            handleSegmentCreationSuccess={handleSegmentCreationSuccess}
          />
        )}

        <Row className="project-wrapper my-3">
          {isLoading ? (
            <Loader />
          ) : (
            customersList && (
              <CustomersTable
                customersResults={customersList}
                customersCount={customersCount}
              />
            )
          )}
        </Row>
      </Container>
    </div>
  );
};

export default AllCustomerTabComponent;
