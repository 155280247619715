enum LAYOUT_TYPES {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
  TWOCOLUMN = "twocolumn",
  SEMIBOX = "semibox"
}

enum LAYOUT_MODE_TYPES {
  LIGHTMODE = "light",
  DARKMODE = "dark"
}

enum LAYOUT_SIDEBAR_TYPES {
  LIGHT = "light",
  DARK = "dark",
  GRADIENT = "gradient",
  GRADIENT_2 = "gradient-2",
  GRADIENT_3 = "gradient-3",
  GRADIENT_4 = "gradient-4"
}

enum LAYOUT_WIDTH_TYPES {
  FLUID = "lg",
  BOXED = "boxed"
}

enum LAYOUT_POSITION_TYPES {
  FIXED = "fixed",
  SCROLLABLE = "scrollable"
}

enum LAYOUT_TOPBAR_THEME_TYPES {
  LIGHT = "light",
  DARK = "dark"
}

enum LEFT_SIDEBAR_SIZE_TYPES {
  DEFAULT = "lg",
  COMPACT = "md",
  SMALLICON = "sm",
  SMALLHOVER = "sm-hover"
}

enum LEFT_SIDEBAR_VIEW_TYPES {
  DEFAULT = "default",
  DETACHED = "detached"
}

enum LEFT_SIDEBAR_IMAGE_TYPES {
  NONE = "none",
  IMG1 = "img-1",
  IMG2 = "img-2",
  IMG3 = "img-3",
  IMG4 = "img-4"
}

enum PERLOADER_TYPES {
  ENABLE = "enable",
  DISABLE = "disable"
}

enum SIDEBAR_VISIBILITY_TYPES {
  SHOW = "show",
  HIDDEN = "hidden"
}

enum Features {
  CRM = "CRM",
  ANALYTICS = "ANALYTICS"
}

enum OrganizationStatus {
  SUSPENDED = "SUSPENDED",
  ACTIVE = "ACTIVE",
  SYNCING = "SYNCING"
}

export {
  LAYOUT_TYPES,
  LAYOUT_MODE_TYPES,
  LAYOUT_SIDEBAR_TYPES,
  LAYOUT_WIDTH_TYPES,
  LAYOUT_POSITION_TYPES,
  LAYOUT_TOPBAR_THEME_TYPES,
  LEFT_SIDEBAR_SIZE_TYPES,
  LEFT_SIDEBAR_VIEW_TYPES,
  LEFT_SIDEBAR_IMAGE_TYPES,
  PERLOADER_TYPES,
  SIDEBAR_VISIBILITY_TYPES,
  Features,
  OrganizationStatus
};
