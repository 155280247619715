import { Container, Row, Button } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import UiTabs from "../../../pages/BaseUi/UiTabs/UiTabs";
import { useToggle } from "../../../customHooks";
import CampaignCreateModal from "./CampaignCreateModal";

const CampaignPage = () => {
  document.title = "Campaigns | MarketBuzz";
  const [showCreateCampaign, toggleShowCreateCampaign] = useToggle(false);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="SMS Campaigns" pageTitle="Campaigns" />
        <Row className="project-wrapper">
          <div className="d-flex justify-content-end mb-2">
            <Button
              color="primary"
              onClick={toggleShowCreateCampaign}
              className="me-4"
            >
              + Create New Campaign
            </Button>
          </div>

          <UiTabs />
        </Row>
      </Container>
      {showCreateCampaign && (
        <CampaignCreateModal
          showModal={showCreateCampaign}
          closeModal={toggleShowCreateCampaign}
          fade={true}
        />
      )}
    </div>
  );
};

export default CampaignPage;
