import { useState, useCallback, useMemo, useEffect } from "react";
import type {
  JsonGroup,
  Config,
  ImmutableTree,
  BuilderProps
} from "@react-awesome-query-builder/bootstrap";
import {
  Query,
  Builder,
  Utils as QbUtils
} from "@react-awesome-query-builder/bootstrap";
import { Button, Card, CardBody } from "reactstrap";
import { Utility } from "../../../utils";
import { useToggle } from "../../../customHooks";
import CustomerSegementCreate from "./CustomerSegmentCreate";
import { filterConfig } from "./CustomersFilterConfig";
import { useCustomersStore } from "../../../Store/store";
import { CustomersFilterTasks } from "./context/CustomerDataContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "@react-awesome-query-builder/bootstrap/css/styles.css";
import "./CustomerFilter.scss";

const queryValue: JsonGroup = { id: QbUtils.uuid(), type: "group" };

interface CustomersFilterProps {
  isLoading: boolean;
  setIsFilterResult: (isFilterResult: boolean) => void;
  onFilterCustomers: (filters) => void;
  currentFilters: {};
  setCurrentFilters: (currentFilters: {}) => void;
  handleSegmentCreationSuccess: () => void;
}

const CustomersFilter: React.FC<CustomersFilterProps> = ({
  isLoading,
  setIsFilterResult,
  onFilterCustomers,
  currentFilters,
  setCurrentFilters,
  handleSegmentCreationSuccess
}) => {
  const [setQueryTask] = useCustomersStore((state) => [state.setQueryTask]);
  const [state, setState] = useState({
    tree: QbUtils.loadTree(queryValue),
    config: filterConfig
  });

  const [showCreateFilter, toggleShowCreateFilter] = useToggle(false);

  const onChange = useCallback(
    (immutableTree: ImmutableTree, filterConfig: Config) => {
      setState((prevState) => ({
        ...prevState,
        tree: immutableTree,
        config: filterConfig
      }));

      const jsonTree = QbUtils.getTree(immutableTree);
      // `jsonTree` can be saved to backend, and later loaded to `queryValue`
    },
    []
  );

  const onApplyFilter = useCallback(() => {
    setIsFilterResult(true);
    setCurrentFilters(QbUtils.getTree(state.tree));
    onFilterCustomers(QbUtils.getTree(state.tree));
  }, [state, setCurrentFilters, onFilterCustomers, setIsFilterResult]);

  const onClickSaveFilter = useCallback(() => {
    onApplyFilter();
    toggleShowCreateFilter();
  }, [onApplyFilter, toggleShowCreateFilter]);

  const onClickClearFilter = useCallback(() => {
    setState({
      tree: QbUtils.loadTree(queryValue),
      config: filterConfig
    });
    setCurrentFilters({});
    setIsFilterResult(false);
    setQueryTask({ task: CustomersFilterTasks.getAllCustomers, query: {} });
  }, [
    filterConfig,
    setCurrentFilters,
    setState,
    setIsFilterResult,
    setQueryTask
  ]);

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container" style={{ padding: "10px" }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  const hasFilters = useMemo(() => {
    return !Utility.isEqualObjects(QbUtils.getTree(state.tree), queryValue);
  }, [state]);

  useEffect(() => {
    if (
      currentFilters &&
      !Utility.isEmptyObject(currentFilters) &&
      !Utility.isEqualObjects(QbUtils.getTree(state.tree), currentFilters)
    ) {
      setState({
        tree: QbUtils.loadTree(queryValue),
        config: filterConfig
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  return (
    <Card className="member-filter">
      <CardBody>
        <Query
          {...filterConfig}
          value={state.tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
        />
        {hasFilters && (
          <div className="text-center mt-4">
            <Button
              className="mx-2"
              color="primary"
              size="sm"
              disabled={
                isLoading ||
                Utility.isEqualObjects(
                  QbUtils.getTree(state.tree),
                  currentFilters
                )
              }
              onClick={onApplyFilter}
            >
              Apply Filter
            </Button>

            <Button
              color="outline-primary"
              size="sm"
              disabled={isLoading}
              onClick={onClickSaveFilter}
            >
              Save Filter {showCreateFilter}
            </Button>

            <Button
              className="mx-2"
              color="outline-danger"
              size="sm"
              disabled={isLoading}
              onClick={onClickClearFilter}
            >
              Clear Filter
            </Button>
          </div>
        )}
      </CardBody>
      {showCreateFilter && (
        <CustomerSegementCreate
          show={showCreateFilter}
          onHide={toggleShowCreateFilter}
          currentFilters={currentFilters}
          handleSegmentCreationSuccess={handleSegmentCreationSuccess}
        />
      )}
    </Card>
  );
};
export default CustomersFilter;
