import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { useOrganizationStore } from "../Store/store";

const BillingTopBar = () => {
  const [credits] = useOrganizationStore((state) => [state.credits]);
  const navigate = useNavigate();

  const handleBillingPageNavigation = useCallback(() => {
    navigate("/billing");
  }, [navigate]);

  return (
    <div>
      <span className={`${credits < 10 ? "text-danger" : ""}`}>
        <span className="fw-semibold">{credits}</span> Buzz Credits
      </span>
      <Button
        color="primary"
        className="mx-3"
        onClick={handleBillingPageNavigation}
      >
        Buy Credits
      </Button>
    </div>
  );
};

export default BillingTopBar;
