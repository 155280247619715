import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./AllRoutes";
import AuthRoutes from "./AuthRoutes";
import NonAuthLayout from "../Layouts/NonAuthLayout";

const RoutesComponent = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<AuthRoutes>{route.component}</AuthRoutes>}
              key={idx}
            />
          ))}
        </Route>
        {/* Default Redirect */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Fragment>
  );
};

export default RoutesComponent;
