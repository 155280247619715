import { useMemo, useState } from "react";
import { Table } from "reactstrap";
import { useMutation, useQueries } from "@tanstack/react-query";
import AdminAuthService from "../../../services/AdminAuthService";
import Pagination from "../../../Components/Common/Pagination";
import { getOragnizations, getConfigurationData } from "../../../services";
import moment from "moment";
import "./OrganizationTable.scss";

interface OrganizationTableProps {
  searchResults: [];
  isSearchResult: boolean;
}

const OrganizationsTable: React.FC<OrganizationTableProps> = ({
  searchResults,
  isSearchResult
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [skip, setSkip] = useState<number>(0);

  const queries = useQueries({
    queries: [
      {
        queryKey: ["organizations"],
        queryFn: getOragnizations
      },
      {
        queryKey: ["config"],
        queryFn: getConfigurationData
      }
    ]
  });

  const [organizationsQuery, configQuery] = queries;

  const isLoading = organizationsQuery.isLoading || configQuery.isLoading;
  const error = organizationsQuery.error || configQuery.error;
  const organizationsData = organizationsQuery.data;
  const configData = configQuery.data;

  const switchOrganization = useMutation({
    mutationFn: (selectedOrganizationId: string) => {
      return AdminAuthService.impersonate(selectedOrganizationId);
    },
    onSuccess(data) {
      window.open(data.url, "_blank");
    }
  });

  const handleSwitch = (event: any) => {
    event.preventDefault();
    const selectedOrganizationId = event.currentTarget.getAttribute("data-id");
    switchOrganization.mutate(selectedOrganizationId);
  };

  const displayedOrganizations = useMemo(() => {
    return isSearchResult ? searchResults : organizationsData?.items || [];
  }, [isSearchResult, searchResults, organizationsData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="table-responsive table-card my-3">
        <Table className="align-middle table-nowrap mb-0 customer-table">
          <thead className="table-light">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date of installation</th>
              <th scope="col">Customers Count</th>
              <th scope="col">Total Revenue</th>
              <th scope="col">Transaction Count</th>
              <th scope="col" style={{ width: "150px" }}>
                Avg. Order Value
              </th>
              <th scope="col">Category of Business</th>
              <th scope="col">Application installed</th>
              <th scope="col">Credit Balance</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrganizations.length > 0 && configData ? (
              displayedOrganizations.map((org) => (
                <tr
                  key={org.id}
                  onClick={handleSwitch}
                  data-id={org.id}
                  className=""
                >
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="flex-grow-1">{org.name}</div>
                    </div>
                  </td>
                  <td>{moment(org?.createdOn).format("YYYY-MM-DD")}</td>
                  <td>{org?.totalCustomersCount || 0}</td>
                  <td>{org?.totalTransactionSum.toFixed(2) || 0}</td>
                  <td>{org?.totalTransactionCount || 0}</td>
                  <td>
                    {(
                      Number(org?.totalTransactionSum) /
                      Number(org?.totalTransactionCount)
                    ).toFixed(2) || 0}
                  </td>
                  <td>{org?.type}</td>
                  <td>
                    {configData?.clover?.apps[org?.appReferenceId]?.appName}
                  </td>
                  <td>{org?.credits}</td>
                </tr>
              ))
            ) : (
              <tr className="mt-5">
                <td className="text-center" colSpan={4}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Pagination
        dataCount={displayedOrganizations.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={limit}
        setSkip={setSkip}
        loadCustomerData={getOragnizations}
      />
    </>
  );
};

export default OrganizationsTable;
