import { Card, CardBody, CardTitle } from "reactstrap";
import numeral from "numeral";

interface StatCardProps {
  title: string;
  count: number;
  isCurrency: boolean;
}

const StatCard: React.FC<StatCardProps> = ({ title, count, isCurrency }) => {
  return (
    <>
      <Card className="text-bg-primary">
        <CardBody className="pb-2">
          <div className="d-flex flex-row">
            <CardTitle
              tag="h5"
              className="text-white d-flex justify-content-between w-100"
            >
              {title} <small className="d-inline ml-auto">(All Time)</small>
            </CardTitle>
          </div>
          <h1 className="my-1 text-white">
            {isCurrency
              ? `$${numeral(count).format("0.0a")}`
              : numeral(count).format("0,0")}
          </h1>
        </CardBody>
      </Card>
    </>
  );
};

export default StatCard;
