import VerticalLayout from "./Layouts/index";
import RoutesComponent from "./Routes";
import AuthStoreProvider from "./Store/Providers/AuthStoreProvider";
import CustomerStoreProvider from "./Store/Providers/CustomerStoreProvider";
import OrganizationStoreProvider from "./Store/Providers/OrganizationProvider";
import ConfigStoreProvider from "./Store/Providers/ConfigProvider";
import { useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AxiosError } from "axios";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/scss/themes.scss";

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID
});

declare module "@tanstack/react-query" {
  interface Register {
    defaultError: AxiosError;
  }
}

const queryClient = new QueryClient();
function App() {
  const location = useLocation();
  const isBasicView =
    location.pathname === "/login" || location.pathname === "/admin/switch";
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthStoreProvider>
          {isBasicView ? (
            <RoutesComponent />
          ) : (
            <ConfigStoreProvider>
              <OrganizationStoreProvider>
                <CustomerStoreProvider>
                  <VerticalLayout>
                    <RoutesComponent />
                  </VerticalLayout>
                </CustomerStoreProvider>
              </OrganizationStoreProvider>
            </ConfigStoreProvider>
          )}
        </AuthStoreProvider>
      </QueryClientProvider>
      <ToastContainer />
    </>
  );
}

export default App;
