import { Navigate } from "react-router-dom";
import { useAuthStore } from "../Store/store";
import Loader from "../Components/Common/Loader";

const AuthProtected = (props: any) => {
  const [isLoggedIn, initCompleted] = useAuthStore((state) => [
    state.isLoggedIn,
    state.initCompleted
  ]);

  if (!initCompleted) {
    return <Loader isLoading={true} />;
  }
  if (!isLoggedIn()) {
    return (
      <Navigate
        to={{
          pathname: "/login"
        }}
      />
    );
  }

  return <>{props.children}</>;
};

export default AuthProtected;
