const Constants = {
  REACT_APP_API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL ||
    "https://api.beta.marketbuzz.ai/api/crm_service/",
  REACT_APP_FRONTNEND_URL:
    process.env.REACT_APP_FRONTNEND_URL || "https://app.beta.marketbuzz.ai/",
  REACT_APP_WEBSOCKET_BASE_URL:
    process.env.REACT_APP_WEBSOCKET_BASE_URL ||
    "wss://api.staging.marketbuzz.ai/api/crm_service/ws"
};

export enum UserRole { //TODO: Need to refer from types. For some reason, it gives an error at the moment
  USER = "USER",
  SUPERADMIN = "SUPERADMIN"
}

export enum WebsocketMessageAction { //TODO: Need to refer from types. For some reason, it gives an error at the moment
  GET_SYNC_PROGRESS = "GET_SYNC_PROGRESS"
}

export const GTM = {
  events: {
    loginEvent: "loginEvent",
    logoutEvent: "logoutEvent",
    userProfileUpdateEvent: "userProfileUpdateEvent",
    campaignCreatedEvent: "campaignCreatedEvent"
  }
};

export const CustomersTasks = {
  filterCustomers: "FilterCustomers",
  searchCustomers: "SearchCustomers",
  getAllCustomers: "GetAllCustomers"
};

export default Constants;
