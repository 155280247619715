import { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";

const CustomerSegmentDropdown = ({
  allSegments,
  toggleTab,
  isAllCustomers,
  title
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      <UncontrolledDropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="mb-2"
      >
        <DropdownToggle color="light">
          {title} <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu>
          {isAllCustomers && (
            <DropdownItem
              key="all_customers"
              onClick={() => toggleTab("all_customers")}
            >
              All Customers
            </DropdownItem>
          )}
          {allSegments?.length > 0 ? (
            allSegments.map((segment) => (
              <DropdownItem
                key={segment.id}
                onClick={() => toggleTab(segment.id)}
              >
                {segment.name}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem key="no-segment">No Segments</DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default CustomerSegmentDropdown;
